var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<thead>\r\n<tr>\r\n    <th>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"icon",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":35}}}))
    + "</th>\r\n    <th>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"code",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":35}}}))
    + "</th>\r\n    <th>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"balance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":38}}}))
    + "</th>\r\n    <th>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"remove",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":37}}}))
    + "</th>\r\n</tr>\r\n</thead>\r\n<tbody></tbody>\r\n";
},"useData":true});