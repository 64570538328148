var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":24}}}) : helper)))
    + "</strong>:&nbsp;"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":49}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":49},"end":{"line":7,"column":78}}})) != null ? stack1 : "")
    + "\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return ",";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"text-right\">\r\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"stockAmount") || (depth0 != null ? lookupProperty(depth0,"stockAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stockAmount","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":23}}}) : helper)))
    + "&nbsp;<i class=\"fas fa-warehouse "
    + alias4(((helper = (helper = lookupProperty(helpers,"stockClass") || (depth0 != null ? lookupProperty(depth0,"stockClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stockClass","hash":{},"data":data,"loc":{"start":{"line":12,"column":56},"end":{"line":12,"column":70}}}) : helper)))
    + "\"></i>\r\n    </td>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_default_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":90}}}))
    + "\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <s class=\"discount-original-price\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_default_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":125}}}))
    + "</s><br/>\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":82}}}))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\r\n    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"flat_product") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\r\n    <br>\r\n    <span class=\"text-muted\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"flat_product") : depth0)) != null ? lookupProperty(stack1,"product") : stack1)) != null ? lookupProperty(stack1,"sku") : stack1), depth0))
    + "</span>\r\n    <br/>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"product_attributes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":13}}})) != null ? stack1 : "")
    + "</td>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"useStock") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":14,"column":7}}})) != null ? stack1 : "")
    + "<td class=\"text-right\">\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"product_default_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "</td>\r\n<td class=\"text-center\">\r\n    <div>\r\n        <div class=\"loader-wrapper\">\r\n            <div class=\"loader\"></div>\r\n        </div>\r\n        <div class=\"flex-column\">\r\n            <button class=\"btn btn-regular btn-success\" data-action=\"add\" data-ui=\"add\">\r\n                <i class=\"far fa-plus\"></i> <i class=\"fas fa-euro-sign\"></i>\r\n            </button>\r\n        </div>\r\n    </div>\r\n</td>\r\n";
},"useData":true});