var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img src=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"provider_method") : depth0)) != null ? lookupProperty(stack1,"image_url") : stack1), depth0))
    + "\" alt=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"code","hash":{},"data":data,"loc":{"start":{"line":3,"column":54},"end":{"line":3,"column":62}}}) : helper)))
    + "\" class=\"img-responsive\" width=\"30\">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        -\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"provider_method") : depth0)) != null ? lookupProperty(stack1,"image_url") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "</td>\r\n<td>\r\n    "
    + alias2(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":12}}}) : helper)))
    + "\r\n</td>\r\n<td>\r\n    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"balance") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":12,"column":52}}}))
    + "\r\n</td>\r\n<td>\r\n    <button class=\"btn btn-danger btn-regular btn-fix-margin-on-icons\" data-action=\"delete\"><i class=\"fa fa-trash\" aria-hidden=\"true\"></i></button>\r\n</td>";
},"useData":true});