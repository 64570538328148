var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"createCustomer") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "            <label class=\"control-label\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"select_a_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":81}}}))
    + " ("
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"optional",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":83},"end":{"line":14,"column":114}}}))
    + ")</label>\r\n            <div data-region=\"customer\" class=\"inputs__customer\"></div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <div class=\"row\">\r\n                    <div class=\"col-xs-6\">\r\n                        <button class=\"btn btn-primary btn-block form-button\" data-action=\"new-customer\" data-private=\"true\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_new_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":125},"end":{"line":7,"column":164}}}))
    + "</button>\r\n                    </div>\r\n                    <div class=\"col-xs-6\">\r\n                        <button class=\"btn btn-primary btn-block form-button\" data-action=\"new-customer\" data-private=\"false\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_new_company",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":126},"end":{"line":10,"column":164}}}))
    + "</button>\r\n                    </div>\r\n                </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--content\">\r\n    <div class=\"inputs\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"attachCustomer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <label class=\"control-label\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"balance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":37},"end":{"line":18,"column":67}}}))
    + "</label>\r\n        <div data-region=\"balance\" class=\"inputs__balance\"></div>\r\n\r\n        <label class=\"control-label\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"scan_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":37},"end":{"line":21,"column":69}}}))
    + "</label>\r\n        <div data-region=\"code\" class=\"inputs__code\"></div>\r\n    </div>\r\n    <button class=\"btn btn-success accept\" data-action=\"save\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"accept",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":62},"end":{"line":24,"column":91}}}))
    + "</button>\r\n    <div data-region=\"keypad\" class=\"keypad-container\"></div>\r\n</div>";
},"useData":true});