var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label for=\"duration\" class=\"title\">\r\n    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"duration_in_minutes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":46}}}))
    + "\r\n</label>\r\n\r\n<div class=\"input-group\">\r\n    <input type=\"number\" id=\"duration\" class=\"form-control\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"duration_in_minutes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":73},"end":{"line":6,"column":115}}}))
    + "\" data-action=\"duration\" data-ui=\"duration\" value=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"extra") : depth0)) != null ? lookupProperty(stack1,"duration_in_minutes") : stack1), depth0))
    + "\">\r\n    <span class=\"input-group-btn\">\r\n        <button class=\"btn designer-box rounded\" data-action=\"save-duration\">\r\n            <i class=\"fas fa-save\"></i>\r\n        </button>\r\n    </span>\r\n</div>";
},"useData":true});