var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <button class=\"btn designer-box rounded\" data-action=\"delete\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"remove",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":78},"end":{"line":37,"column":107}}}))
    + "</button>\r\n                <button class=\"btn designer-box rounded\" data-action=\"pay\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pay",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":38,"column":75},"end":{"line":38,"column":101}}}))
    + "</button>\r\n                <button class=\"btn designer-box rounded\" data-action=\"save\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"apply",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":39,"column":76},"end":{"line":39,"column":104}}}))
    + "</button>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"btn designer-box rounded\" data-action=\"save\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"create_a_new_appointment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":76},"end":{"line":41,"column":123}}}))
    + "</button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"loader-wrapper\" data-name=\"layout\">\r\n    <div class=\"loader\"></div>\r\n</div>\r\n\r\n<div class=\"row\">\r\n\r\n    <div class=\"col-md-5\">\r\n        <h3>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"appointment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":46}}}))
    + "</h3>\r\n\r\n        <label class=\"control-label text-bold\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"select_an_employee",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":47},"end":{"line":10,"column":88}}}))
    + "</label>\r\n        <div data-region=\"employee\"></div>\r\n\r\n        <label class=\"control-label text-bold\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"select_a_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":47},"end":{"line":13,"column":87}}}))
    + "</label>\r\n        <div data-region=\"customer\"></div>\r\n\r\n        <label class=\"control-label text-bold\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"date_and_time",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":47},"end":{"line":16,"column":83}}}))
    + "</label>\r\n        <div data-region=\"date-time\"></div>\r\n\r\n        <label class=\"control-label text-bold\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"appointment_duration",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":47},"end":{"line":19,"column":90}}}))
    + "</label>\r\n        <div data-region=\"length\"></div>\r\n\r\n        <label class=\"control-label text-bold\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"treatments",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":47},"end":{"line":22,"column":80}}}))
    + "</label>\r\n        <div data-region=\"sum\"></div>\r\n\r\n\r\n    </div>\r\n\r\n\r\n    <div class=\"col-md-7\">\r\n        <h3>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"treatments",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":45}}}))
    + "</h3>\r\n\r\n        <div data-region=\"treatments\"></div>\r\n\r\n        <div class=\"buttons\">\r\n            <button class=\"btn designer-box rounded\" data-action=\"cancel\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":74},"end":{"line":35,"column":103}}}))
    + "</button>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"item_id") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n\r\n</div>";
},"useData":true});