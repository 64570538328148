var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--content\">\r\n    <div class=\"inputs\">\r\n        <div class=\"row\">\r\n            <div class=\"col-xs-6\">\r\n                <button class=\"btn btn-primary btn-block form-button\" data-action=\"new-customer\" data-private=\"true\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_new_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":117},"end":{"line":5,"column":156}}}))
    + "</button>\r\n            </div>\r\n            <div class=\"col-xs-6\">\r\n                <button class=\"btn btn-primary btn-block form-button\" data-action=\"new-customer\" data-private=\"false\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"add_new_company",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":118},"end":{"line":8,"column":156}}}))
    + "</button>\r\n            </div>\r\n        </div>\r\n        <label class=\"control-label\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"select_a_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":37},"end":{"line":11,"column":77}}}))
    + "</label>\r\n        <div data-region=\"customer\" class=\"inputs__customer\"></div>\r\n\r\n        <label class=\"control-label\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"balance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":37},"end":{"line":14,"column":67}}}))
    + "</label>\r\n        <div data-region=\"balance\" class=\"inputs__balance\"></div>\r\n\r\n        <label class=\"control-label\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"scan_customer_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":37},"end":{"line":17,"column":78}}}))
    + "</label>\r\n        <div data-region=\"code\" class=\"inputs__code\"></div>\r\n    </div>\r\n    <button class=\"btn btn-success accept\" data-action=\"save\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"accept",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":62},"end":{"line":20,"column":91}}}))
    + "</button>\r\n    <div data-region=\"keypad\" class=\"keypad-container\"></div>\r\n</div>";
},"useData":true});