var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"{0}_hours_and_{1}_minutes",(depth0 != null ? lookupProperty(depth0,"hours") : depth0),(depth0 != null ? lookupProperty(depth0,"minutes") : depth0),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":62}}}));
},"useData":true});