var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"header\">\r\n    <div class=\"dialog--header\">\r\n        <h2 data-ui=\"title\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"open_ccv_transaction",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":71}}}))
    + "</h2>\r\n        <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n    </div>\r\n</div>\r\n\r\n<div class=\"dialog--content\">\r\n\r\n    <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"there_is_an_unfinished_pin_transaction_that_needs_to_be_printed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":94}}}))
    + "</h2>\r\n    <br>\r\n\r\n    <button class=\"btn btn-info btn-regular\" data-action=\"print\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"print_receipt_copy",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":65},"end":{"line":13,"column":106}}}))
    + "</button>\r\n</div>\r\n";
},"useData":true});