var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"alert alert-info\">\r\n            <h4>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"this_customer_has_{discount_percentage}percent_pernament_discount",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":106}}}))
    + "</h4>\r\n        </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"open_invoinces",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":49}}}))
    + "</h2>\r\n        <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":13,"column":11},"end":{"line":13,"column":26}}}) : helper)))
    + "</p>\r\n        <p>\r\n            <button class=\"btn btn-info designer-box rounded btn-regular\" data-actions=\"customer-invoices\">\r\n                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"view_customer_s_outstanding_invoices",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":75}}}))
    + "\r\n            </button>\r\n        </p>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <h2>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"recent_customer_notes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":56}}}))
    + "</h2>\r\n        <div data-region=\"notes\"></div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\r\n    <h2>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"attention",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":40}}}))
    + "</h2>\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div data-region=\"view\" class=\"dialog--content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discount_percentage") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasOpenInvoice") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"customerHasNotes") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"useData":true});