var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\r\n    <h2 data-ui=\"title\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"amount_from_till",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":63}}}))
    + "</h2>\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\" style=\"height: 50vh;\">\r\n\r\n    <div class=\"first\">\r\n        <div>\r\n            <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"from_amount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":50}}}))
    + "</h2>\r\n            <div data-region=\"first\"></div>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"second\">\r\n        <div>\r\n            <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"till_amount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":50}}}))
    + "</h2>\r\n            <div data-region=\"second\"></div>\r\n        </div>\r\n    </div>\r\n\r\n    <div data-region=\"keypad\" class=\"keypad\"></div>\r\n</div>\r\n\r\n<div class=\"dialog--footer\">\r\n    <button class=\"btn btn-default\" data-action=\"close\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":56},"end":{"line":25,"column":85}}}))
    + "</button>\r\n    <button class=\"btn btn-success\" data-action=\"apply\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"apply",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":56},"end":{"line":26,"column":84}}}))
    + "</button>\r\n</div>";
},"useData":true});