var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"title\">\r\n    "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"flat_product") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\r\n</span>\r\n\r\n<span class=\"duration\">\r\n    "
    + alias1(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\durationRender.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"duration_in_seconds") : depth0),{"name":"common$durationRender","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":51}}}))
    + "\r\n</span>\r\n\r\n<button class=\"btn designer-box rounded\" data-action=\"add\">\r\n    <i class=\"fas fa-plus\"></i>\r\n</button>";
},"useData":true});