var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"title\">\r\n    "
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":12}}}) : helper)))
    + "\r\n</span>\r\n\r\n<span class=\"duration\">\r\n    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\durationRender.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"extra") : depth0)) != null ? lookupProperty(stack1,"duration_in_seconds") : stack1),{"name":"common$durationRender","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":57}}}))
    + "\r\n</span>\r\n\r\n<button class=\"btn designer-box rounded\" data-action=\"time\">\r\n    <i class=\"fas fa-clock\"></i>\r\n</button>\r\n\r\n<button class=\"btn designer-box rounded\" data-action=\"remove\">\r\n    <i class=\"fas fa-trash\"></i>\r\n</button>";
},"useData":true});