var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\r\n    <h2 data-ui=\"title\"></h2>\r\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n</div>\r\n<div class=\"dialog--content\">\r\n    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"are_you_sure_you_want_to_add_{count}_item_s_to_order_{number}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":90}}}))
    + " <br/>\r\n    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"new_order_value_will_be_{new_value}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":64}}}))
    + "<br>\r\n    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"added_items_will_be_marked_as_picked_up_on_store",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":77}}}))
    + "\r\n</div>\r\n\r\n<div class=\"dialog--footer\">\r\n    <button class=\"btn btn-success btn-regular\" data-action=\"confirm\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"confirm",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":70},"end":{"line":12,"column":100}}}))
    + "</button>\r\n    <button class=\"btn btn-default btn-regular\" data-action=\"close\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":68},"end":{"line":13,"column":97}}}))
    + "</button>\r\n</div>\r\n";
},"useData":true});