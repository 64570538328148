var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"confirm_print btn btn-success btn-regular\" data-action=\"confirm_print\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"confirm_and_print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":94},"end":{"line":31,"column":134}}}))
    + "</button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"header\">\r\n    <div class=\"dialog--header\">\r\n        <h2 data-ui=\"title\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"cash_drawer_mutation",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":71}}}))
    + "</h2>\r\n        <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\r\n    </div>\r\n</div>\r\n\r\n\r\n\r\n<div class=\"dialog--content\">\r\n\r\n    <button class=\"in btn btn-light btn-regular designer-box rounded element-focus\" data-action=\"in\" data-ui=\"in\">\r\n        <i class=\"fas fa-inbox-in text-success\"></i>\r\n        <span>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"in",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":14},"end":{"line":14,"column":39}}}))
    + "</span>\r\n    </button>\r\n\r\n    <button class=\"out btn btn-light btn-regular designer-box rounded\" data-action=\"out\" data-ui=\"out\">\r\n        <i class=\"fas fa-inbox-out text-danger\"></i>\r\n        <span>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"out",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":14},"end":{"line":19,"column":40}}}))
    + "</span>\r\n    </button>\r\n\r\n    <div class=\"input\">\r\n        <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"cash_in_out",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":46}}}))
    + "</h2>\r\n        <div data-region=\"cash\"></div>\r\n        <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"note",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":39}}}))
    + "</h2>\r\n        <textarea class=\"form-control\" data-ui=\"note\" rows=\"3\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"note",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":76},"end":{"line":26,"column":103}}}))
    + "\" data-action=\"note\"></textarea>\r\n    </div>\r\n\r\n    <button class=\"confirm btn btn-success btn-regular\" data-action=\"confirm\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"confirm",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":78},"end":{"line":29,"column":108}}}))
    + "</button>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_receipt_printer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "\r\n    <div data-region=\"keypad\" class=\"keys full-child-height\"></div>\r\n</div>\r\n";
},"useData":true});