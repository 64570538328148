var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"save-and-select\">\r\n                        <i class=\"fas fa-user-plus\"></i> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"add_new_customer_and_select",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":57},"end":{"line":17,"column":107}}}))
    + "\r\n                    </button>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"save-and-select\" style=\"margin-right: 15px\">\r\n                        <i class=\"fas fa-user-plus\" style=\"margin-right: 5px;\"></i> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"add_new_company_and_select",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":84},"end":{"line":21,"column":133}}}))
    + "\r\n                    </button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--content__create-customer\">\r\n    <div class=\"block full rounded\">\r\n\r\n        <h2>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"create_a_new_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":56}}}))
    + "</h2>\r\n        <hr>\r\n\r\n        <form onsubmit=\"return false;\" method=\"post\">\r\n"
    + ((stack1 = container.invokePartial(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\shop.cash-register-retail\\templates\\customers\\formPartial.hbs"),depth0,{"name":"modules/shop.cash-register-retail/templates/customers/formPartial","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n            <br>\r\n            <div class=\"form-group form-actions\">\r\n                <button class=\"btn btn-default btn-regular\" data-action=\"back\">\r\n                    <i class=\"fas fa-arrow-left\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":83}}}))
    + "\r\n                </button>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n\r\n        </form>\r\n\r\n    </div>\r\n\r\n    <div data-region=\"popup\" class=\"hidden\"></div></div>";
},"usePartial":true,"useData":true});